import React from "react";
import "./cardResume.scss";
import classNames from "classnames";
import { Link } from "gatsby";
import Image from "../../modules/Image/image.js";
import {Title} from "../../elements/Title/title";
import Column from "../../structure/Column/column";
import {Icon} from "../../elements/Icon/icon";

export const CardResume = props => {
    const dataCard = props.dataCard;
    const productMeta = props.productMeta;
    const textStatic = props.textStatic;
    const classnames = classNames(`m-card-resume`, {

    });

    return (
        <div className={props.className ? props.className + ` ` + classnames : classnames}>
            <Column row>
                <Column col="half" colSm="full" className={`marg-b32 m-card-resume--img`}>
                    <Image filename={dataCard.image_logo} alt={dataCard.name}/>
                </Column>
                <Column col="half" colSm="full" row className={`marg-b32`}>
                    <Column className="m-card-resume--info">
                        <p className="m-card-resume--info-label">{dataCard.category_name} {dataCard.bank_name}</p>
                        <Title normal size20 className={`m-card-resume--info-text marg-t0`} content={dataCard.short_name} />
                    </Column>
                    <div>
                        <Column col="half" className={`m-card-resume--info`}>
                            <p className="m-card-resume--info-label">{textStatic.card_cost_rate_title}</p>
                            <Title normal size20 className={`m-card-resume--info-text marg-t0`} content={`${dataCard.cost_rate} %`} />
                        </Column>
                        <Column col="half" className={`m-card-resume--info`}>
                            <p className="m-card-resume--info-label">{textStatic.card_cost_admin_title}</p>
                            <Title normal size20 className={`m-card-resume--info-text marg-t0`} content={`${productMeta.currency_symbol}${dataCard.cost_admin}`} />
                        </Column>
                    </div>
                    <div>
                        <Column col="half" className={`marg-b16 m-card-resume--category`}>
                            <Icon inlineBlock verticalMiddle icon={"credit-card.png"} width={`34`} />
                            <p className="m-card-resume--category-text">{dataCard.category_name}</p>
                        </Column>
                        <Column col="half" className={`marg-b16`}>
                            {
                                Object.keys(dataCard.transmitter).map(function(d){
                                    return <Icon className={`marg-r5`} icon={dataCard.transmitter[d]["slug"] + ".png"} height={`34`} />
                                })
                            }
                        </Column>
                    </div>
                    <div className="m-card-resume--links">
                        <Column col="half">
                            <Link to={`#${textStatic.product_slug_transfer}`}>+ {textStatic.product_transfer}</Link>
                        </Column>
                        <Column col="half">
                            <Link to={`#${textStatic.product_slug_requirements}`}>+ {textStatic.product_requirements}</Link>
                        </Column>
                    </div>

                </Column>
            </Column>
        </div>
    );
};