import React from "react";
import Layout from "../../components/layout/layout.js";
import Module from "../../components/structure/Module/module.js";
import Wrapper from "../../components/structure/Wrapper/wrapper.js";
import Column from "../../components/structure/Column/column.js";
import Box from "../../components/modules/Box/box.js";
import Seo from "../../components/seo/seo.js";
import {Title} from "../../components/elements/Title/title";
import {Breadcrumbs} from "../../components/modules/Breadcrumbs/breadcrumbs";
import RichText from "../../components/modules/RichText/richText.js";
import Image from "../../components/modules/Image/image.js";
import Faq from "../../components/modules/Faq/faq";
import OtherProduct from "../../components/modules/OtherProduct/otherProduct.js";
import WidgetBanks from "../../components/modules/WidgetBanks/widgetBanks.js";
import JsonBreadcrumbs from "../../components/seo/ld-json/jsonBreadcrumbs.js";
import Resume from "../../components/modules/Resume/resume";
import {CardResume} from "../../components/modules/CardResume/cardResume.js";
import {CardsOther} from "../../components/modules/CardsOther/cardsOther.js";
import {Rating} from "../../components/modules/Rating/rating";
import {Opinions} from "../../components/modules/Opinions/opinions";
import AdsenseManager from '../../components/modules/Adsense/AdsenseManager'

const Card = props => {
    const dataCard = props.pageContext.data;
    const seo = props.pageContext.seo;
    const productMeta = props.pageContext.productMeta;
    const textStatic = props.pageContext.textStatic;
    const rating = true;//this.props.pageContext.rating || false;
    const country_code = seo.country_code;
    const asks = props.pageContext.asks;
    const otherCard = props.pageContext.otherCard;
    const otherAccount = props.pageContext.otherAccount;
    const h2_other_cards = props.pageContext.h2_other_cards;
    const h2_other_bank_account = props.pageContext.h2_other_bank_account;
    const adsense = props.pageContext.adsense;
    const opinionsCurrentSourceName = dataCard.name;
    const opinionsCurrentSourceUrl = seo.url + "/";
    const jsontld = {
        "data": {
            "name": `${seo.mainTitle}`,
            "image": `${seo.image}`,
        }
    };

    return (
        <Layout>
            <Seo lang={seo.lang} title={seo.title} url={seo.url} image={seo.image} description={seo.description} robotsIndex={seo.robotsIndex} robotsFollow={seo.robotsFollow}/>
            <Module bgPr>
                <Wrapper white>
                    <Breadcrumbs content={dataCard.breadcrumbs} host={seo.host}/>
                    <JsonBreadcrumbs data={dataCard.breadcrumbs} host={seo.host}/>
                    <Column row>
                        <Column col="fifth" colSm="half" colXs="full">
                            <Image filename={dataCard.bank_image} alt={dataCard.bank_name} />
                        </Column>
                        <Column col="fifth-complete" colSm="half" colXs="full">
                            <Title tag={`h1`} size20 content={seo.mainTitle}/>
                        </Column>
                    </Column>
                    <Column row>
                        <Column row col="forth-complete" colSm="full">
                            {rating && (
                                <Column colXs="full" col="half">
                                    <Rating
                                        textStatic={textStatic}
                                        country_code={country_code}
                                        object_name={"card"}
                                        object_id={dataCard.id}
                                        jsontld_parent={jsontld}
                                        jsontld_parent_type="FinancialService"
                                    />
                                </Column>
                            )}
                        </Column>
                    </Column>
                    <Column row>
                        <Column col="forth-complete" colSm="full">
                            <Box bgPr borderTop>
                                <CardResume
                                    dataCard={dataCard}
                                    productMeta={productMeta}
                                    textStatic={textStatic}
                                />

                                <Resume title={textStatic.summary_title} >
                                    <ul>
                                        <li><a href={`#${textStatic.product_slug_general_info}`}>{textStatic.tab_main_info}</a></li>
                                        <li><a href={`#${textStatic.product_slug_transfer}`}>{textStatic.product_transfer}</a></li>
                                        <li><a href={`#${textStatic.product_slug_requirements}`}>{textStatic.product_requirements}</a></li>
                                        <li><a href={`#${textStatic.product_slug_faq}`}>{textStatic.faq_title}</a></li>
                                        {otherCard && (
                                            <li><a href={`#${textStatic.card_slug_other}`}>{h2_other_cards}</a></li>
                                        )}
                                        {otherAccount && (
                                            <li><a href={`#${textStatic.bank_account_slug_other}`}>{h2_other_bank_account}</a></li>
                                        )}
                                        <li><a href={`#${textStatic.product_slug_opinions}`}>{textStatic.opinions_title}</a></li>
                                    </ul>
                                </Resume>

                                <Title id={textStatic.product_slug_general_info} tag={`h2`} size20 content={textStatic.tab_main_info} />

                                <RichText className={`marg-t30`} content={dataCard.info} />

                                <AdsenseManager adsense={adsense}/>

                                <Image className={`marg-b32`} filename={dataCard.image} alt={dataCard.name}/>

                                <Column row className={`marg-t30`}>
                                    <Title id={textStatic.product_slug_transfer} centered tag={`h2`} size20 content={textStatic.product_transfer} />
                                    <RichText listPadLeft className={`marg-t30 pad-l60`} content={dataCard.transfer} />
                                </Column>

                                <AdsenseManager adsense={adsense}/>

                                <Column className={`marg-t30`}>
                                    <Title id={textStatic.product_slug_requirements} centered tag={`h2`} size20 content={textStatic.product_requirements} />
                                    <RichText listPadLeft className={`marg-t30 pad-l60`} content={dataCard.requirements} />

                                    <AdsenseManager adsense={adsense}/>

                                    <Faq id={textStatic.product_slug_faq} className={`marg-t60`} asks={asks} title={textStatic.faq_title}/>

                                    {otherCard && (
                                        <CardsOther dataCards={otherCard} title={h2_other_cards} productMeta={productMeta} textStatic={textStatic}/>
                                    )}

                                    {otherAccount && (
                                        <OtherProduct type={"account"} id={textStatic.bank_account_slug_other} className={`marg-t30`} title={h2_other_bank_account} products={otherAccount}/>
                                    )}

                                    <Opinions
                                        textStatic={textStatic}
                                        defaultCurrentSourceName={opinionsCurrentSourceName}
                                        defaultCurrentSourceUrl={opinionsCurrentSourceUrl}
                                        seo={seo}
                                        object_name={"card"}
                                        country_code={country_code}
                                        object_id={dataCard.id}
                                    />
                                </Column>

                            </Box>
                        </Column>
                        <Column col="forth" colSm="full">
                            <Box bgPr>
                                <WidgetBanks/>
                            </Box>
                        </Column>
                    </Column>

                </Wrapper>

            </Module>
        </Layout>
    )
}

export default Card;